import { Sponsorstripe } from '@sch-inventory/advertory/sites/aftenposten/index.js';
import React, { useEffect, useMemo } from 'react';

import type { SectionPageProps } from './types.js';
import type { CuratePulseTrackingCustomProperties } from '../../../../core/utils/types.js';

import Navigation from '../../components/commonComponents/Navigation/Navigation.js';
import { splitToChunks } from '../front/frontHelpers.js';
import Teaser from '../../components/teasers/Teaser.js';
import BundleDesktop from '../../components/bundles/BundleDesktop.js';
import Footer from '../../components/commonComponents/Footer.js';
import BundleMobile from '../../components/bundles/BundleMobile.js';
import { ListsProvider } from '../../components/saving/ListsProvider.js';
import { useSessionData } from '../../../../../public-src/core/js/schibsted-account/react.server.js';
import useInitializeAds from '../../hooks/useInitAds.js';
import { usePulseContext } from '../../../../core/mandatory-integrations/pulse/PulseProvider.js';
import { trackSectionPageView, withByfunnPulse } from '../../../pulse/index.js';
import Ad from '../../components/ads/Ad/Ad.js';
import { insertAdsPlacements } from '../../components/ads/Ad/helpers.js';
import { shouldCompressBundle } from '../../components/bundles/utils.js';
import ChatWidget from '../../components/vinkomat/index.js';

const SectionPage: React.FC<SectionPageProps> = ({
  response,
  enableChatbot,
  renderContext,
  activeSection,
  isLoggedIn,
}) => {
  const { siteVersion, isHermesApp } = renderContext;
  const isMobile = siteVersion === 'phone';

  let mobileRows = 1;

  const { registerViewElementTracking, trackViewListing, getEnvironmentId } =
    usePulseContext();

  const personalization: CuratePulseTrackingCustomProperties = useMemo(
    () => ({
      variant: response.properties.id,
      removeOnRead: response.personalization.removeOnRead,
      removeOnImpressed: response.personalization.removeOnImpressed,
      personalizedResults: response.personalization.personalizedResults,
    }),
    [
      response.properties.id,
      response.personalization.removeOnRead,
      response.personalization.removeOnImpressed,
      response.personalization.personalizedResults,
    ],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    activeSection &&
      trackSectionPageView(trackViewListing, activeSection, personalization);

    registerViewElementTracking();
  }, [
    trackViewListing,
    activeSection,
    personalization,
    registerViewElementTracking,
  ]);

  const session = useSessionData();
  useInitializeAds(getEnvironmentId, session, siteVersion);

  const chunks = splitToChunks(response.items);
  const chunksWithAds = insertAdsPlacements(
    isMobile,
    chunks,
    'frontpage',
    'frontpage',
  );

  return (
    <ListsProvider>
      <div className={`${siteVersion}-front section`}>
        <Navigation
          fullWidth={!isMobile}
          activeSection={activeSection}
          isSticky
          isHermesApp={isHermesApp}
        />

        <Sponsorstripe placementId="sponsorstripe" />

        {chunksWithAds.map((c, i): React.ReactNode => {
          const index = isMobile && i > 0 ? mobileRows : i + 1;

          const componentsToRender = [];

          if (
            activeSection === 'mat-og-drikke' &&
            i === 1 &&
            isLoggedIn &&
            enableChatbot
          ) {
            componentsToRender.push(<ChatWidget key={i} />);
          }

          switch (c.type) {
            case 'teaser':
              if (c.fullWidthTeaser) {
                mobileRows++;

                componentsToRender.push(
                  <Teaser key={`teaser-${index}`} {...c} index={index} />,
                );
              } else {
                componentsToRender.push(<></>);
              }
              break;

            case 'chunk':
            case 'bundle':
              mobileRows += shouldCompressBundle(c, isMobile)
                ? 2
                : c.items.length;

              componentsToRender.push(
                isMobile ? (
                  <BundleMobile key={`chunk-${index}`} {...c} index={index} />
                ) : (
                  <BundleDesktop key={`chunk-${index}`} {...c} index={index} />
                ),
              );
              break;

            case 'ad':
              mobileRows++;

              componentsToRender.push(
                <Ad
                  key={`Ad-${c.id}`}
                  id={c.id}
                  cssClass={c.cssClass}
                  isMobile={isMobile}
                />,
              );
              break;
            default:
              componentsToRender.push(<></>);
          }

          return componentsToRender;
        })}
        <Footer isMobile={isMobile} />
      </div>
    </ListsProvider>
  );
};

const SectionPageWithPulse = withByfunnPulse(SectionPage);

export { SectionPageWithPulse as SectionPage };
